import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const SoaService = {
  SendSoa (id) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/task/${id}/send`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  get (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/soa/${id}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postSend (id, data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v3/soa/${id}/send`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getRecipients (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/soa/${id}/recipients`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default SoaService

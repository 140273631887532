<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
    <div class="container" style="max-height: 90vh; overflow-y: auto">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
        <div class="row">
          <!--  Actions -->
          <div class="col-8">
            <h1 class="subtitle font-18">Selecionar contatos:</h1>
          </div>
          <div class="col-4">
            <div class="flexbox px-4 py-3 mb-3 pull-right">
              <div class="btn-group" role="group" aria-label="false">
                <a class="btn  btn-outline-light  pull-right" href="javascript:;" v-on:click="onSendNow">
                  {{ $t('actions.send_now') }}
                </a>
              </div>
            </div>
          </div>
          <!-- Contatos-->
          <div class="col-12 mb-5">
            <local-data-vue-table
              track-by="idpessoa_contato"
              :filter-fields="filterFields"
              :per-page="2000" :fields="fieldsTable"
              :data="recipients"
              no-data-template="Nenhuma fatura.">
              <!-- TOTAL -->
              <template slot="total" slot-scope="props">
                <div class="custom-totais">
                  <div v-if="props.rowData.Residual_Amount > 0">
                    {{ props.rowData.Currency }} {{
                      $util.numberFormat(props.rowData.Residual_Amount, 2, ',', '.', null)
                    }}
                  </div>
                  <div v-else class="text-danger">
                    {{ props.rowData.Currency }} {{
                      $util.numberFormat(props.rowData.Residual_Amount, 2, ',', '.', null)
                    }}
                  </div>
                </div>
              </template>
            </local-data-vue-table>
          </div>

          <!--  Actions -->
          <div class="col-12">
            <div class="flexbox px-4 py-3 mb-3 pull-right">
              <div class="btn-group" role="group" aria-label="false">
                <a class="btn  btn-outline-light  pull-right" href="javascript:;" v-on:click="onSendNow">
                  {{ $t('actions.send_now') }}
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'
import CustomVueTableCheck from '@/components/Table/CustomVueTableCheck'

// Components
import LocalDataVueTable from '@/components/Table/LocalDataVueTable'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import SoaService from '@/services/SoaService'

export default {
  name: 'SoaSendSettingsSelectContacts',
  mixins: [VuetableFieldCheckboxMixin],
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.eroyal.portalAccess.title') + ' ' + this.$i18n.t('report.eroyal.title') + ' - %s'
    }
  },
  data () {
    return {
      recipients: {
        data: []
      },
      faturas: {
        data: []
      },
      filterFields: [
        {
          name: 'Nome',
          title: 'Nome',
          type: 'text',
          value: null
        },
        {
          name: 'Email',
          title: 'Email',
          type: 'text',
          value: null
        }
      ],
      total: {
        total: 0,
        qtd_faturas: 0,
        total_moedas: []
      },
      loadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v3/soa',
      sortOrder: [
        { field: 'agente', direction: 'asc' }
      ],
      showQueries: false,
      valor: 10.0,
      customerList: [],
      tempPeriod: 'monthly',
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        employee: null,
        year: null,
        month: null
      },
      firstFilter: true,
      filter: {}
    }
  },
  components: {
    LocalDataVueTable,
    Loading
  },
  mounted () {
    let _this = this
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.isLoading = true

    let promises = [
      SoaService.getRecipients(_this.id)
    ]

    Promise.all(promises).then((values) => {
      // If is edit populate values
      _this.recipients = values[0].data
    }).finally(() => {
      _this.isLoading = false
    })
  },
  methods: {
    onClose () {
      let someData = { x: 123, y: 456 }
      this.$emit('close', someData)
    },
    onSendNow (task) {
      var _this = this
      _this.isLoading = true

      let id = _this.id

      let selected = _this.selectedInvoices

      let recipients = _this.recipients.data.filter((item) => {
        return item.selected
      })

      let data = {}
      data.selected = selected
      data.recipients = recipients

      SoaService.postSend(id, data).then(res => {
        _this.$router.push({ name: 'SoaSendList' })
        _this.onClose()
        _this.$alertSwal.toast('Adicionado na fila de envio!')
      }).catch(() => {
        _this.onClose()
        _this.$alertSwal.toast('Não foi possível adicionar na fila de envio. Tente novamente<br/>Se o problema persistir por favor informe o administrados do sistema.', 'error')
      }).finally(() => {
        _this.isLoading = false
      })
    },
    onPaginationData (pagination) {
      this.total.total = pagination.total
      this.total.qtd_faturas = parseInt(pagination.qtd_faturas)
      this.total.total_moedas = pagination.total_moedas
    },
    toogleQueries () {
      this.showQueries = !this.showQueries
    },
    getActiveFilterList () {
      let activeFilterList = {
        period: null,
        employee: null,
        year: null
      }

      if (this.filters.filPeriodo === 'quarter') {
        activeFilterList.quarter = null
      } else if (this.filters.filPeriodo === 'monthly') {
        activeFilterList.month = null
      }

      return activeFilterList
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getQuarter (date) {
      let quarter = parseInt(Math.ceil((date.getMonth() + 1) / 3))

      return quarter
    },
    buildFilters () {
      if (['monthly', 'quarter', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = 'monthly'
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      let today = new Date()
      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Semestre: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filQuarter)) {
        this.filters.filQuarter = this.getQuarter(today)
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.employee = this.filters.filEmployee
      this.filter.year = this.filters.filYear
      this.filter.quarter = this.filters.filQuarter
      this.filter.month = this.filters.filMonth

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      // Period
      if (this.$refs.view_type_quarter.classList.contains('active')) {
        this.filters.filPeriodo = 'quarter'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filQuarter = this.$refs.quarter ? parseInt(this.$refs.quarter.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filQuarter = this.getQuarter(today)
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    }
    // End Filter
  },
  computed: {
    valorPorMoedaTotal () {
      let totalPorMoeda = {}

      if (this.faturas && this.faturas.data) {
        for (let i in this.faturas.data) {
          let fatura = this.faturas.data[i]
          if (!totalPorMoeda.hasOwnProperty(fatura.Currency)) {
            totalPorMoeda[fatura.Currency] = {
              selected: {
                residual_amount: 0.0
              },
              total: {
                residual_amount: 0.0
              }
            }
          }

          totalPorMoeda[fatura.Currency].total.residual_amount += fatura.Residual_Amount

          if (fatura.hasOwnProperty('selected') && fatura.selected === true) {
            totalPorMoeda[fatura.Currency].selected.residual_amount += fatura.Residual_Amount
          }
        }
      }

      return totalPorMoeda
    },
    qtdFaturasTotal () {
      if (this.faturas && this.faturas.data) {
        return this.faturas.data.length
      }

      return 0
    },
    qtdFaturasSelecionadas () {
      if (this.faturas && this.faturas.data) {
        return this.faturas.data.filter((value) => {
          return value.selected === true
        }).length
      }

      return 0
    },
    fieldsTable () {
      return [
        {
          name: CustomVueTableCheck
        },
        {
          name: 'Nome',
          title: 'Nome',
          sortField: 'Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Email',
          title: 'E-mail',
          sortField: 'Email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'atribuicao',
          title: 'Atribuicao',
          sortField: 'atribuicao',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    },
    activeFilters () {
      return this.filters
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // this.getReportSellerData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    selectedInvoices: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
  .subtitle {
    margin-top: 3.0rem;
    margin-bottom: 0.8rem;
  }

  .close-button {
    position: absolute;
    top: 4px;
    right: 3px;
    z-index: 99;
  }

  .query-info {
    position: absolute;
    top: 26px;
    right: 36px;
  }
</style>

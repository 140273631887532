<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'quarter'}" v-on:click="setTempPeriod('quarter')" ref="view_type_quarter"  data-toggle="pill" data-period="quarter">{{ $t('period.quarter') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-goals-list-filter" v-on:submit.prevent="applyFilter()">
              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in $util.years(2021)" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'quarter'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filQuarter" id="quarter" name="quarter" ref="quarter" >
                          <option v-for="(quarter, index) in $util.quarters()" v-bind:key="index" :value="index" :selected="filters.filQuarter === parseInt(index)" >{{ quarter }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.quarter') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in $util.months()" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-10 mb-3 filter-container">
        <filter-container :activeFilterList="getActiveFilterList()" />
      </div>

      <div class="col-12">
        <button-back>
          Enviar SOA - Statement of account
        </button-back>
      </div>

      <!-- Quantidade Faturas -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="qtdFaturasSelecionadas"
                 :hide-goal="false"
                 :black="true"
                 :decimals="0"
                 :meta="qtdFaturasTotal"
                 :label-realizado="'Selecionadas'"
                 :label-meta="'Total'"
                 :show-chart="false"
      >
        <template slot="title">
          Quantidade de faturas
        </template>
      </card-goal>

      <!-- Total por moeda -->
      <card-goal v-for="(total_moeda, index) in valorPorMoedaTotal" v-bind:key="index" class-root="col-12 col-md-4 mb-4"
                 :realizado="total_moeda.selected.residual_amount"
                 :hide-goal="true"
                 :decimals="2"
                 :abrev="false"
                 :meta="total_moeda.total.residual_amount"
                 :label-realizado="'Selecionadas'"
                 :label-meta="'Total'"
                 :show-chart="false"
      >
        <template slot="title">
          {{ index }} {{ total_moeda.moeda_sigla }}
        </template>
      </card-goal>

      <card class-root="col-12 mt-2" >
        <div class="row">
          <div class="col-12 mb-5">
            <!--  Actions -->
            <div class="flexbox px-4 py-3 mb-3">
              <div class="d-flex align-items-center">

              </div>
              <div class="btn-group" role="group" aria-label="false">
                <a class="btn  btn-outline-light  pull-right" href="javascript:;" v-on:click="onSendNow">
                  {{ $t('actions.send_now') }}
                </a>
              </div>
            </div>

            <local-data-vue-table
              track-by="IdRegistro_Financeiro"
              :filter-fields="filterFields"
              :per-page="2000" :fields="fieldsTable"
              :data="faturas"
               no-data-template="Nenhuma fatura.">
              <!-- TOTAL -->
              <template slot="total" slot-scope="props">
                <div class="custom-totais">
                    <div v-if="props.rowData.Residual_Amount > 0">
                      {{ props.rowData.Currency }} {{ $util.numberFormat(props.rowData.Residual_Amount, 2, ',', '.', null) }}
                    </div>
                    <div v-else class="text-danger">
                      {{ props.rowData.Currency }} {{ $util.numberFormat(props.rowData.Residual_Amount, 2, ',', '.', null) }}
                    </div>
                </div>
              </template>
            </local-data-vue-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'
import CustomVueTableCheck from '@/components/Table/CustomVueTableCheck'
import SoaSendSettingsSelectContacts from './SoaSendSettingsSelectContacts'

// Components
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Card from '@/views/Report/Seller/2022/Card'
import CardGoal from '@/views/Report/Seller/2022/CardGoal'
import ButtonBack from '@/components/Navigate/ButtonBack'
import LocalDataVueTable from '@/components/Table/LocalDataVueTable'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import SoaService from '@/services/SoaService'

export default {
  name: 'SoaSendSettings',
  mixins: [VuetableFieldCheckboxMixin],
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.eroyal.portalAccess.title') + ' ' + this.$i18n.t('report.eroyal.title') + ' - %s'
    }
  },
  data () {
    return {
      faturas: {
        data: []
      },
      filterFields: [
        {
          name: 'Currency',
          title: 'Moeda',
          type: 'text',
          value: null
        },
        {
          name: 'Our_Reference',
          title: 'Processo',
          type: 'text',
          value: null
        }
      ],
      total: {
        total: 0,
        qtd_faturas: 0,
        total_moedas: []
      },
      loadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v3/soa',
      sortOrder: [
        { field: 'agente', direction: 'asc' }
      ],
      showQueries: false,
      valor: 10.0,
      customerList: [],
      tempPeriod: 'monthly',
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        employee: null,
        year: null,
        month: null
      },
      firstFilter: true,
      filter: {}
    }
  },
  components: {
    LocalDataVueTable,
    Card,
    CardGoal,
    FilterSideBar,
    Loading,
    FilterContainer,
    ButtonBack
  },
  mounted () {
    let _this = this
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.isLoading = true
    let id = this.$route.params.id

    let promises = [
      SoaService.get(id)
    ]

    Promise.all(promises).then((values) => {
      // If is edit populate values
      _this.faturas = values[0].data
    }).finally(() => {
      _this.isLoading = false
    })
  },
  methods: {
    onSendNow (task) {
      var _this = this
      _this.isLoading = true

      let id = parseInt(this.$route.params.id)

      let selected = _this.faturas.data.filter((fatura) => {
        return (fatura.hasOwnProperty('selected') && fatura.selected === true)
      }).map((x) => {
        return x.IdRegistro_Financeiro
      })

      _this.isLoading = false
      _this.$modal.show(
        SoaSendSettingsSelectContacts,
        { isSuperAdmin: _this.isSuperAdmin, id: id, selectedInvoices: selected },
        { draggable: false, width: '78%', height: 'auto', adaptive: true },
        { 'before-close': event => {} }
      )
    },
    onPaginationData (pagination) {
      this.total.total = pagination.total
      this.total.qtd_faturas = parseInt(pagination.qtd_faturas)
      this.total.total_moedas = pagination.total_moedas
    },
    toogleQueries () {
      this.showQueries = !this.showQueries
    },
    getActiveFilterList () {
      let activeFilterList = {
        period: null,
        employee: null,
        year: null
      }

      if (this.filters.filPeriodo === 'quarter') {
        activeFilterList.quarter = null
      } else if (this.filters.filPeriodo === 'monthly') {
        activeFilterList.month = null
      }

      return activeFilterList
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getQuarter (date) {
      let quarter = parseInt(Math.ceil((date.getMonth() + 1) / 3))

      return quarter
    },
    buildFilters () {
      if (['monthly', 'quarter', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = 'monthly'
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      let today = new Date()
      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Semestre: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filQuarter)) {
        this.filters.filQuarter = this.getQuarter(today)
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.employee = this.filters.filEmployee
      this.filter.year = this.filters.filYear
      this.filter.quarter = this.filters.filQuarter
      this.filter.month = this.filters.filMonth

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      // Period
      if (this.$refs.view_type_quarter.classList.contains('active')) {
        this.filters.filPeriodo = 'quarter'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filQuarter = this.$refs.quarter ? parseInt(this.$refs.quarter.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filQuarter = this.getQuarter(today)
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    }
    // End Filter
  },
  computed: {
    valorPorMoedaTotal () {
      let totalPorMoeda = {}

      if (this.faturas && this.faturas.data) {
        for (let i in this.faturas.data) {
          let fatura = this.faturas.data[i]
          if (!totalPorMoeda.hasOwnProperty(fatura.Currency)) {
            totalPorMoeda[fatura.Currency] = {
              selected: {
                residual_amount: 0.0
              },
              total: {
                residual_amount: 0.0
              }
            }
          }

          totalPorMoeda[fatura.Currency].total.residual_amount += fatura.Residual_Amount

          if (fatura.hasOwnProperty('selected') && fatura.selected === true) {
            totalPorMoeda[fatura.Currency].selected.residual_amount += fatura.Residual_Amount
          }
        }
      }

      return totalPorMoeda
    },
    qtdFaturasTotal () {
      if (this.faturas && this.faturas.data) {
        return this.faturas.data.length
      }

      return 0
    },
    qtdFaturasSelecionadas () {
      if (this.faturas && this.faturas.data) {
        return this.faturas.data.filter((value) => {
          return value.selected === true
        }).length
      }

      return 0
    },
    fieldsTable () {
      return [
        {
          name: CustomVueTableCheck
        },
        {
          name: 'Agent',
          title: 'Agente',
          sortField: 'Agent',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Our_Reference',
          title: 'Processo',
          sortField: 'Our_Reference',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'total',
          title: 'Total',
          width: '20%',
          dataClass: 'text-right',
          titleClass: 'text-center'
        },
        {
          name: 'acoes',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    activeFilters () {
      return this.filters
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // this.getReportSellerData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .subtitle {
    margin-top: 3.0rem;
    margin-bottom: 0.8rem;
  }

  .query-info {
    position: absolute;
    top: 26px;
    right: 36px;
  }
</style>

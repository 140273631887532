<template>
  <th v-if="isHeader" class="vuetable-th-component-checkbox">
    <label class="checkbox checkbox-primary"><input type="checkbox"
                                                    @change="toggleAllCheckboxCustom($event)"
                                                    :checked="isAllItemsInCurrentPageSelectedCustom"
    ><span></span></label>
  </th>
  <td v-else
      class="vuetable-td-component-checkbox"
  >
    <label class="checkbox checkbox-primary">
      <input type="checkbox" v-model="rowData.selected">
      <span></span></label>
  </td>
</template>
<script>
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'
import Vue from 'vue'

export default {
  name: 'custom-vue-table',
  mixins: [VuetableFieldCheckboxMixin],
  methods: {
    toggleAllCheckboxCustom (event) {
      let allSelected = this.isAllItemsInCurrentPageSelectedCustom
      this.vuetable.tableData.forEach((element, index) => {
        Vue.set(this.vuetable.tableData[index], 'selected', !allSelected)
      })
    }
  },
  computed: {
    isAllItemsInCurrentPageSelectedCustom () {
      let totalRows = 0
      let selectedRows = 0
      if (this.vuetable.tableData) {
        totalRows = this.vuetable.tableData.length
        selectedRows = this.vuetable.tableData.filter((x) => {
          return x.hasOwnProperty('selected') && x.selected === true
        }).length
      }

      return totalRows === selectedRows
    }
  }
}
</script>
